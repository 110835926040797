.injectableTooltip__container {
    color: #111;
    position: relative;
    width: 400px;
    transition: all 0.3s ease-in-out;
}

.injectableTooltip__body {
    transition: all 0.3s ease-in-out;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px;
    padding: 10px 0;
}

.injectableTooltip__closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
}

.injectableTooltip__arrow {
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 100px;
    height: 50px;
    overflow: hidden;
}
.injectableTooltip__arrow:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.4) 0px -2px 6px;
}

.injectableTooltip__arrow--up {
    top: -50px;
}
.injectableTooltip__arrow--up:after {
    top: 40px;
    left: 25px;
    transform: rotate(45deg);
    /* Prefixes... */
}

.injectableTooltip__arrow--down {
    bottom: -50px;
}
.injectableTooltip__arrow--down:after {
    top: -40px;
    left: 25px;
    transform: rotate(45deg);
    /* Prefixes... */
}

@font-face {
    font-family: "BMWGroupTNProTT-Regular";
    src: url("../fonts/BMWGroupTNProTT-Regular.eot");
    src: url("../fonts/BMWGroupTNProTT-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/BMWGroupTNProTT-Regular.woff2") format("woff2"),
        url("../fonts/BMWGroupTNProTT-Regular.woff") format("woff"),
        url("../fonts/BMWGroupTNProTT-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "BMWGroupTNCondensedProTT-Reg";
    src: url("../fonts/BMWGroupTNCondensedProTT-Reg.eot");
    src: url("../fonts/BMWGroupTNCondensedProTT-Reg.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/BMWGroupTNCondensedProTT-Reg.woff2") format("woff2"),
        url("../fonts/BMWGroupTNCondensedProTT-Reg.woff") format("woff"),
        url("../fonts/BMWGroupTNCondensedProTT-Reg.ttf") format("truetype");
}

@font-face {
    font-family: "BMWGroupTNCondensedProTT-Bd";
    src: url("../fonts/BMWGroupTNCondensedProTT-Bd.eot");
    src: url("../fonts/BMWGroupTNCondensedProTT-Bd.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/BMWGroupTNCondensedProTT-Bd.woff2") format("woff2"),
        url("../fonts/BMWGroupTNCondensedProTT-Bd.woff") format("woff"),
        url("../fonts/BMWGroupTNCondensedProTT-Bd.ttf") format("truetype");
}
